<template>
  <component-frame title="Tables">
    <div class="sg-page__heading">
      <div class="on-this-page">
        <div class="on-this-page-title">On This Page</div>
        <ul class="on-this-page-anchor-list">
          <li><a href="#" v-scroll-to="'#data-tables'">Default table</a></li>
          <li><a href="#" v-scroll-to="'#data-tables'">Complex table</a></li>
          <li>
            <a href="#" v-scroll-to="'#responsive'">Responsive stacked table</a>
          </li>
          <li>
            <a href="#" v-scroll-to="'#search-table'">Grouped table with search</a>
          </li>
        </ul>
      </div>
    </div>

    <h3 id="data-tables">Default Table</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">Reference</th>
                <th scope="col">City</th>
                <th class="text-right" scope="col">Version</th>
                <th class="text-right" scope="col">Updated</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>RE#120</td>
                <td>Vancouver</td>
                <td class="text-right">128</td>
                <td class="text-right">June 8, 2015</td>
              </tr>
              <tr>
                <td>RE#121</td>
                <td>Kelowna</td>
                <td class="text-right">3</td>
                <td class="text-right">August 16, 2016</td>
              </tr>
              <tr>
                <td>RE#123</td>
                <td>Richmond</td>
                <td class="text-right">73</td>
                <td class="text-right">January 12, 2014</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;table class="table"&gt;
  &lt;thead&gt;
    &lt;tr&gt;
      &lt;th scope="col"&gt;Reference&lt;/th&gt;
      &lt;th scope="col"&gt;City&lt;/th&gt;
      &lt;th class="text-right" scope="col"&gt;Version&lt;/th&gt;
      &lt;th class="text-right" scope="col"&gt;Updated&lt;/th&gt;
    &lt;/tr&gt;
  &lt;/thead&gt;
  &lt;tbody&gt;
    &lt;tr&gt;
      &lt;td&gt;RE#120&lt;/td&gt;
      &lt;td&gt;Vancouver&lt;/td&gt;
      &lt;td class="text-right"&gt;128&lt;/td&gt;
      &lt;td class="text-right"&gt;June 8, 2015&lt;/td&gt;
    &lt;/tr&gt;
    &lt;tr&gt;
      &lt;td&gt;RE#121&lt;/td&gt;
      &lt;td&gt;Kelowna&lt;/td&gt;
      &lt;td class="text-right"&gt;3&lt;/td&gt;
      &lt;td class="text-right"&gt;August 16, 2016&lt;/td&gt;
    &lt;/tr&gt;
    &lt;tr&gt;
      &lt;td&gt;RE#123&lt;/td&gt;
      &lt;td&gt;Richmond&lt;/td&gt;
      &lt;td class="text-right"&gt;73&lt;/td&gt;
      &lt;td class="text-right"&gt;January 12, 2014&lt;/td&gt;
    &lt;/tr&gt;
  &lt;/tbody&gt;
&lt;/table&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            Use for simpler tabular data with a low number of columns and rows
          </li>
          <li>
            Use for tabular data where row/column comparison is less important
          </li>
          <li>Dates and numbers should be right aligned for readability</li>
          <li>Do not use tables for layout</li>
        </ul>
      </div>
    </div>

    <h3 id="complex">Complex Table</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <table class="table table-hover table-brand-primary">
            <thead>
              <tr>
                <th><input type="checkbox" /></th>
                <th v-for="(col, index) in columns" @click="sortTable(col, index)">
                  {{ col }}
                  <div v-if="col == sortColumn" class="table-sort" :class="ascending ? 'table-sort--up' : 'table-sort--down'"></div>
                  <div v-else class="table-sort"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in rows">
                <td><input type="checkbox" /></td>
                <td>{{ row.name }}</td>
                <td>{{ row.modified }}</td>
                <td>{{ row.status }}</td>
              </tr>
            </tbody>
          </table>

          <nav aria-label="Page navigation">
            <div class="d-flex flex-row justify-content-between">
              <div class="d-flex align-items-center">
                <span class="pr-2">View:</span>
                <ul class="pagination m-0">
                  <li class="page-item">
                    <a class="page-link" href="javascript:void(0)">10</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="javascript:void(0)">25</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="javascript:void(0)">50</a>
                  </li>
                </ul>
              </div>

              <ul class="pagination">
                <li class="page-item">
                  <a class="page-link" href="javascript:void(0)" aria-label="Previous">
                    <span aria-hidden="true">
                      <svg class="icon icon-arrow_left">
                        <use xlink:href="/icons/symbol-defs.svg#icon-arrow_left"></use>
                      </svg>
                    </span>
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="javascript:void(0)">1</a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="javascript:void(0)">2</a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="javascript:void(0)">3</a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="javascript:void(0)" aria-label="Next">
                    <span aria-hidden="true">
                      <svg class="icon icon-arrow_right">
                        <use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"></use>
                      </svg>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;table class="table table-hover table-brand-primary"&gt;
  &lt;thead&gt;
    &lt;tr&gt;
      &lt;th scope="row"&gt;&lt;input type="checkbox"&gt;&lt;/th&gt;
      &lt;th scope="row"&gt;File Reference &lt;span class="table-sort"&gt;&lt;/span&gt;&lt;/th&gt;
      &lt;th scope="col"&gt;Modified&lt;/th&gt;
      &lt;th scope="col"&gt;Status&lt;/th&gt;
      &lt;th class="text-right" scope="col"&gt;Menu&lt;/th&gt;
    &lt;/tr&gt;
  &lt;/thead&gt;
  &lt;tbody&gt;
    &lt;tr&gt;
      &lt;td scope="row"&gt;&lt;input type="checkbox"&gt;&lt;/td&gt;
      &lt;td scope="row"&gt;
        &lt;a&gt;&lt;strong&gt;12801 Anderson&lt;/strong&gt;&lt;/a&gt;
      &lt;/td&gt;
      &lt;td data-header="Modified"&gt;Sep 28 2017&lt;/td&gt;
      &lt;td data-header="Status"&gt;&lt;!----&gt;Rejected&lt;/td&gt;
      &lt;td class="text-right" data-header="Actions"&gt;
        &lt;div class="dropdown"&gt;
          &lt;button aria-expanded="false" aria-haspopup="true" class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown" id="dropdownMenu1" type="button"&gt;Action &lt;span class="caret"&gt;&lt;/span&gt;&lt;/button&gt;
          &lt;ul aria-labelledby="dropdownMenu1" class="dropdown-menu dropdown-menu-right"&gt;
            &lt;li&gt;
              &lt;a href="javascript:void(0)"&gt;Table action link&lt;/a&gt;
            &lt;/li&gt;
          &lt;/ul&gt;
        &lt;/div&gt;
      &lt;/td&gt;
    &lt;/tr&gt;
    &lt;tr&gt;
      &lt;td scope="row"&gt;&lt;input type="checkbox"&gt;&lt;/td&gt;
      &lt;td scope="row"&gt;
        &lt;a&gt;&lt;strong&gt;Jiles/Roberts&lt;/strong&gt;&lt;/a&gt;
      &lt;/td&gt;
      &lt;td data-header="Modified"&gt;Sep 17 2107&lt;/td&gt;
      &lt;td data-header="Status"&gt;&lt;span class="badge badge-pill badge-danger"&gt;&nbsp;&lt;/span&gt; Defected&lt;/td&gt;
      &lt;td class="text-right" data-header="Actions"&gt;
        &lt;div class="dropdown"&gt;
          &lt;button aria-expanded="true" aria-haspopup="true" class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown" id="dropdownMenu1" type="button"&gt;Action &lt;span class="caret"&gt;&lt;/span&gt;&lt;/button&gt;
          &lt;ul aria-labelledby="dropdownMenu1" class="dropdown-menu dropdown-menu-right"&gt;
            &lt;li&gt;
              &lt;a href="javascript:void(0)"&gt;Table action link&lt;/a&gt;
            &lt;/li&gt;
          &lt;/ul&gt;
        &lt;/div&gt;
      &lt;/td&gt;
    &lt;/tr&gt;
    &lt;tr&gt;
      &lt;td scope="row"&gt;&lt;input type="checkbox"&gt;&lt;/td&gt;
      &lt;td scope="row"&gt;
        &lt;a&gt;&lt;strong&gt;12764 Smith&lt;/strong&gt;&lt;/a&gt;
      &lt;/td&gt;
      &lt;td data-header="Modified"&gt;Sep 17 2017&lt;/td&gt;
      &lt;td data-header="Status"&gt;&lt;!----&gt;Unsubmitted&lt;/td&gt;
      &lt;td class="text-right" data-header="Actions"&gt;
        &lt;div class="dropdown"&gt;
          &lt;button aria-expanded="true" aria-haspopup="true" class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown" id="dropdownMenu1" type="button"&gt;Action &lt;span class="caret"&gt;&lt;/span&gt;&lt;/button&gt;
          &lt;ul aria-labelledby="dropdownMenu1" class="dropdown-menu dropdown-menu-right"&gt;
            &lt;li&gt;
              &lt;a href="javascript:void(0)"&gt;Table action link&lt;/a&gt;
            &lt;/li&gt;
          &lt;/ul&gt;
        &lt;/div&gt;
      &lt;/td&gt;
    &lt;/tr&gt;
    &lt;tr&gt;
      &lt;td scope="row"&gt;&lt;input type="checkbox"&gt;&lt;/td&gt;
      &lt;td scope="row"&gt;
        &lt;a&gt;&lt;strong&gt;12805 Terries&lt;/strong&gt;&lt;/a&gt;
      &lt;/td&gt;
      &lt;td data-header="Modified"&gt;Sep 04 2017&lt;/td&gt;
      &lt;td data-header="Status"&gt;&lt;!----&gt;Pending&lt;/td&gt;
      &lt;td class="text-right" data-header="Actions"&gt;
        &lt;div class="dropdown"&gt;
          &lt;button aria-expanded="true" aria-haspopup="true" class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown" id="dropdownMenu1" type="button"&gt;Action &lt;span class="caret"&gt;&lt;/span&gt;&lt;/button&gt;
          &lt;ul aria-labelledby="dropdownMenu1" class="dropdown-menu dropdown-menu-right"&gt;
            &lt;li&gt;
              &lt;a href="javascript:void(0)"&gt;Table action link&lt;/a&gt;
            &lt;/li&gt;
          &lt;/ul&gt;
        &lt;/div&gt;
      &lt;/td&gt;
    &lt;/tr&gt;
  &lt;/tbody&gt;
&lt;/table&gt;
&lt;div class="row"&gt;
  &lt;div class="col-md-6"&gt;
    &lt;ul class="pagination"&gt;
      &lt;li&gt;&lt;span&gt;View:&lt;/span&gt;&lt;/li&gt;
      &lt;li&gt;
        &lt;a href="javascript:void(0)"&gt;10&lt;/a&gt;
      &lt;/li&gt;
      &lt;li&gt;
        &lt;a href="javascript:void(0)"&gt;20&lt;/a&gt;
      &lt;/li&gt;
      &lt;li&gt;
        &lt;a href="javascript:void(0)"&gt;50&lt;/a&gt;
      &lt;/li&gt;
    &lt;/ul&gt;
  &lt;/div&gt;
  &lt;div class="col-md-6"&gt;
    &lt;ul class="pagination pull-right"&gt;
      &lt;li&gt;
        &lt;a aria-label="Previous" href="javascript:void(0)"&gt;&lt;span aria-hidden="true"&gt;&lt;svg class="icon icon-arrow_left"&gt;
        &lt;use xlink:href="/icons/symbol-defs.svg#icon-arrow_left" xmlns:xlink="http://www.w3.org/1999/xlink"&gt;&lt;/use&gt;&lt;/svg&gt;&lt;/span&gt;&lt;/a&gt;
      &lt;/li&gt;
      &lt;li class="active"&gt;
        &lt;a href="javascript:void(0)"&gt;1&lt;/a&gt;
      &lt;/li&gt;
      &lt;li&gt;
        &lt;a href="javascript:void(0)"&gt;2&lt;/a&gt;
      &lt;/li&gt;
      &lt;li&gt;
        &lt;a href="javascript:void(0)"&gt;3&lt;/a&gt;
      &lt;/li&gt;
      &lt;li&gt;
        &lt;a href="javascript:void(0)"&gt;4&lt;/a&gt;
      &lt;/li&gt;
      &lt;li&gt;
        &lt;a href="javascript:void(0)"&gt;5&lt;/a&gt;
      &lt;/li&gt;
      &lt;li&gt;
        &lt;a aria-label="Next" href="javascript:void(0)"&gt;&lt;span aria-hidden="true"&gt;&lt;svg class="icon icon-arrow_right"&gt;
        &lt;use xlink:href="/icons/symbol-defs.svg#icon-arrow_right" xmlns:xlink="http://www.w3.org/1999/xlink"&gt;&lt;/use&gt;&lt;/svg&gt;&lt;/span&gt;&lt;/a&gt;
      &lt;/li&gt;
    &lt;/ul&gt;
  &lt;/div&gt;
&lt;/div&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            If rows are sortable, table headers should be presented with an arrow next to the active sort showing the direction
          </li>
          <li>
            Classes .table-brand-primary and .table-brand-secondary will give the table header a background colour
          </li>
        </ul>
      </div>
    </div>

    <h3 id="responsive">Stacked responsive table</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <table class="table table-hover table-stacked table-select-row">
            <thead>
              <tr>
                <th scope="row"><input type="checkbox" /></th>
                <th scope="col">Reference</th>
                <th scope="col">City</th>
                <th scope="col">Version</th>
                <th scope="col">Updated</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="row"><input type="checkbox" /></td>
                <td data-header="Reference">
                  <span class="td-content">RE#120</span>
                </td>
                <td data-header="City">
                  <span class="td-content">Vancouver</span>
                </td>
                <td data-header="Version">
                  <span class="td-content">128</span>
                </td>
                <td data-header="Updated">
                  <span class="td-content">June 8, 2015</span>
                </td>
              </tr>
              <tr>
                <td scope="row"><input type="checkbox" /></td>
                <td data-header="Reference">
                  <span class="td-content">RE#121</span>
                </td>
                <td data-header="City">
                  <span class="td-content">Kelowna</span>
                </td>
                <td data-header="Version"><span class="td-content">3</span></td>
                <td data-header="Updated">
                  <span class="td-content">August 16, 2016</span>
                </td>
              </tr>
              <tr>
                <td scope="row"><input type="checkbox" /></td>
                <td data-header="Reference">
                  <span class="td-content">RE#123</span>
                </td>
                <td data-header="City">
                  <span class="td-content">Richmond</span>
                </td>
                <td data-header="Version">
                  <span class="td-content">73</span>
                </td>
                <td data-header="Updated">
                  <span class="td-content">January 12, 2014</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;table class="table table-hover table-stacked table-select-row"&gt;
  &lt;thead&gt;
    &lt;tr&gt;
      &lt;th scope="row"&gt;&lt;input type="checkbox"&gt;&lt;/th&gt;
      &lt;th scope="col"&gt;Reference&lt;/th&gt;
      &lt;th scope="col"&gt;City&lt;/th&gt;
      &lt;th scope="col"&gt;Version&lt;/th&gt;
      &lt;th scope="col"&gt;Updated&lt;/th&gt;
    &lt;/tr&gt;
  &lt;/thead&gt;
  &lt;tbody&gt;
    &lt;tr&gt;
      &lt;td scope="row"&gt;&lt;input type="checkbox"&gt;&lt;/td&gt;
      &lt;td data-header="Reference"&gt;&lt;span class="td-content"&gt;RE#120&lt;/span&gt;&lt;/td&gt;
      &lt;td data-header="City"&gt;&lt;span class="td-content"&gt;Vancouver&lt;/span&gt;&lt;/td&gt;
      &lt;td data-header="Version"&gt;&lt;span class="td-content"&gt;128&lt;/span&gt;&lt;/td&gt;
      &lt;td data-header="Updated"&gt;&lt;span class="td-content"&gt;June 8, 2015&lt;/span&gt;&lt;/td&gt;
    &lt;/tr&gt;
    &lt;tr&gt;
      &lt;td scope="row"&gt;&lt;input type="checkbox"&gt;&lt;/td&gt;
      &lt;td data-header="Reference"&gt;&lt;span class="td-content"&gt;RE#121&lt;/span&gt;&lt;/td&gt;
      &lt;td data-header="City"&gt;&lt;span class="td-content"&gt;Kelowna&lt;/span&gt;&lt;/td&gt;
      &lt;td data-header="Version"&gt;&lt;span class="td-content"&gt;3&lt;/span&gt;&lt;/td&gt;
      &lt;td data-header="Updated"&gt;&lt;span class="td-content"&gt;August 16, 2016&lt;/span&gt;&lt;/td&gt;
    &lt;/tr&gt;
    &lt;tr&gt;
      &lt;td scope="row"&gt;&lt;input type="checkbox"&gt;&lt;/td&gt;
      &lt;td data-header="Reference"&gt;&lt;span class="td-content"&gt;RE#123&lt;/span&gt;&lt;/td&gt;
      &lt;td data-header="City"&gt;&lt;span class="td-content"&gt;Richmond&lt;/span&gt;&lt;/td&gt;
      &lt;td data-header="Version"&gt;&lt;span class="td-content"&gt;73&lt;/span&gt;&lt;/td&gt;
      &lt;td data-header="Updated"&gt;&lt;span class="td-content"&gt;January 12, 2014&lt;/span&gt;&lt;/td&gt;
    &lt;/tr&gt;
  &lt;/tbody&gt;
&lt;/table&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            Add a .table-stacked class to the table to make it stack on smaller screens
          </li>
          <li>
            If first cell is a checkbox, add .table-select-row to the table and scope="row" to the first th and td elements.
          </li>
          <li>
            Data-header attributes are displayed as cell titles on smaller screens
          </li>
        </ul>
      </div>
    </div>

    <h3 id="search-table">Grouped table with search</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <div class="form-group">
            <div class="input-group align-items-start justify-content-stretch">
              <div class="input-group-prepend col-5 px-0">
                <select class="custom-select" id="exampleFormControlSelect2">
                  <optgroup>
                    <option>Interest Holder or Settlor</option>
                    <option>Land Title or Charge Number</option>
                    <option>Parcel ID (PID)</option>
                    <option>Reporting Body</option>
                    <option>SIN or ITN</option>
                    <option>Transparency Declaration (TD) Number</option>
                    <option>Transparency Report (TR) Number</option>
                  </optgroup>
                  <optgroup>
                    <option>Business Number</option>
                    <option>Certifier</option>
                    <option>Subscriber</option>
                    <option>Transferee</option>
                  </optgroup>
                </select>
              </div>
              <div class="form-group col-6 px-0">
                <input type="text" class="form-control" placeholder="search" />
                <small id="emailHelp" class="form-text text-muted">Search instructions for Interest Holder or Settlor Name to be explained</small>
              </div>

              <div class="input-group-append">
                <button class="btn btn-primary" type="button">
                  <svg class="icon icon-search">
                    <use xlink:href="/icons/symbol-defs.svg#icon-search"></use>
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <p class="lead">
            <strong>4</strong> records found for Interest Holder or Settlor:
            <strong>Jane Smith</strong>
          </p>

          <table class="table table-responsive">
            <thead class="bg-medium">
              <tr>
                <th scope="col">
                  Transparency Declaration
                  <div class="table-sort "></div>
                </th>
                <th scope="col">
                  Transparency Report
                  <div class="table-sort table-sort--down"></div>
                </th>
                <th scope="col">PID</th>
                <th scope="col">Reporting Body</th>
                <th scope="col">Interest Holder or Settlor</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td><a href="#">L000-002-137-TR</a></td>
                <td>
                  <ul class="list-unstyled mb-0">
                    <li class="text-nowrap">000-000-015</li>
                  </ul>
                </td>
                <td>YZ Corporation</td>
                <td><mark>Jane Smith</mark></td>
              </tr>
              <tr>
                <td></td>
                <td><a href="#">L000-002-138-TR</a></td>
                <td>
                  <ul class="list-unstyled mb-0">
                    <li class="text-nowrap">000-222-072</li>
                  </ul>
                </td>
                <td>Sand Trust</td>
                <td><mark>Jane Smith</mark></td>
              </tr>
              <tr>
                <td rowspan="2"><a href="#">L000-002-139-TD</a></td>
                <td><a href="#">L000-002-139-TR01</a></td>
                <td>
                  <ul class="list-unstyled mb-0">
                    <li class="text-nowrap">000-000-018</li>
                    <li class="text-nowrap">000-000-019</li>
                    <li class="text-nowrap">000-000-020</li>
                    <li class="text-nowrap">+12 more</li>
                  </ul>
                </td>
                <td>ABC Corporation</td>
                <td>
                  <ul class="list-unstyled mb-0">
                    <li class="text-nowrap">Ida Bowden</li>
                    <li class="text-nowrap">Kristen Connell</li>
                    <li class="text-nowrap">Eugene Parker</li>
                    <li class="text-nowrap"><mark>Jane Smith</mark></li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td><a href="#">L000-002-139-TR02</a></td>
                <td>
                  <ul class="list-unstyled mb-0">
                    <li class="text-nowrap">000-000-018</li>
                    <li class="text-nowrap">000-000-019</li>
                    <li class="text-nowrap">000-000-020</li>
                    <li class="text-nowrap">+12 more</li>
                  </ul>
                </td>
                <td>MNO Corporation</td>
                <td>Eve Smith</td>
              </tr>
              <tr>
                <td></td>
                <td><a href="#">L000-002-140-TR</a></td>
                <td>
                  <ul class="list-unstyled mb-0">
                    <li class="text-nowrap">000-222-333</li>
                  </ul>
                </td>
                <td>EFG Corporation</td>
                <td><mark>Jane Smith</mark></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="sg-component__code">
          <div class="sg-component__toggle js-toggle-code" data-text-swap="Hide Code" v-toggleCode>
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;div class="form-group"&gt;
  &lt;div class="input-group align-items-start justify-content-stretch"&gt;
    &lt;div class="input-group-prepend col-5 px-0"&gt;
      &lt;select class="custom-select" id="exampleFormControlSelect2"&gt;
        &lt;optgroup&gt;
          &lt;option&gt;Interest Holder or Settlor&lt;/option&gt;
          &lt;option&gt;Land Title or Charge Number&lt;/option&gt;
          &lt;option&gt;Parcel ID (PID)&lt;/option&gt;
          &lt;option&gt;Reporting Body&lt;/option&gt;
          &lt;option&gt;SIN or ITN&lt;/option&gt;
          &lt;option&gt;Transparency Declaration (TD) Number&lt;/option&gt;
          &lt;option&gt;Transparency Report (TR) Number&lt;/option&gt;
        &lt;/optgroup&gt;
        &lt;optgroup&gt;
          &lt;option&gt;Business Number&lt;/option&gt;
          &lt;option&gt;Certifier&lt;/option&gt;
          &lt;option&gt;Subscriber&lt;/option&gt;
          &lt;option&gt;Transferee&lt;/option&gt;
        &lt;/optgroup&gt;
      &lt;/select&gt;
    &lt;/div&gt;
    &lt;div class="form-group col-6 px-0"&gt;
      &lt;input type="text" class="form-control" placeholder="search"&gt;
      &lt;small id="emailHelp" class="form-text text-muted"&gt;Search instructions for Interest Holder or Settlor Name to be explained&lt;/small&gt;
    &lt;/div&gt;

    &lt;div class="input-group-append"&gt;
      &lt;button class="btn btn-primary" type="button"&gt;
        &lt;svg class="icon icon-search"&gt;&lt;use xlink:href="/icons/symbol-defs.svg#icon-search"&gt;&lt;/use&gt;&lt;/svg&gt;
      &lt;/button&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/div&gt;

&lt;p class="lead"&gt;&lt;strong&gt;4&lt;/strong&gt; records found for Interest Holder or Settlor: &lt;strong&gt;Jane Smith&lt;/strong&gt;&lt;/p&gt;

&lt;table class="table"&gt;
  &lt;thead class="bg-medium"&gt;
    &lt;tr&gt;
      &lt;th scope="col"&gt;Transparency Declaration &lt;div class="table-sort "&gt;&lt;/div&gt;&lt;/th&gt;
      &lt;th scope="col"&gt;Transparency Report&lt;div class="table-sort table-sort--down"&gt;&lt;/div&gt;&lt;/th&gt;
      &lt;th scope="col"&gt;PID&lt;/th&gt;
      &lt;th scope="col"&gt;Reporting Body&lt;/th&gt;
      &lt;th scope="col"&gt;Interest Holder or Settlor&lt;/th&gt;
    &lt;/tr&gt;
  &lt;/thead&gt;
  &lt;tbody&gt;
    &lt;tr&gt;
      &lt;td&gt;&lt;/td&gt;
      &lt;td&gt;&lt;a href="#"&gt;L000-002-137-TR&lt;/a&gt;&lt;/td&gt;
      &lt;td&gt;
        &lt;ul class="list-unstyled mb-0"&gt;
          &lt;li class="text-nowrap"&gt;000-000-015&lt;/li&gt;
        &lt;/ul&gt;
      &lt;/td&gt;
      &lt;td&gt;YZ Corporation&lt;/td&gt;
      &lt;td&gt;&lt;mark&gt;Jane Smith&lt;/mark&gt;&lt;/td&gt;
    &lt;/tr&gt;
    &lt;tr&gt;
      &lt;td&gt;&lt;/td&gt;
      &lt;td&gt;&lt;a href="#"&gt;L000-002-138-TR&lt;/a&gt;&lt;/td&gt;
      &lt;td&gt;
        &lt;ul class="list-unstyled mb-0"&gt;
          &lt;li class="text-nowrap"&gt;000-222-072&lt;/li&gt;
        &lt;/ul&gt;
      &lt;/td&gt;
      &lt;td&gt;Sand Trust&lt;/td&gt;
      &lt;td&gt;&lt;mark&gt;Jane Smith&lt;/mark&gt;&lt;/td&gt;
    &lt;/tr&gt;
    &lt;tr&gt;
      &lt;td rowspan="2"&gt;&lt;a href="#"&gt;L000-002-139-TD&lt;/a&gt;&lt;/td&gt;
      &lt;td&gt;&lt;a href="#"&gt;L000-002-139-TR01&lt;/a&gt;&lt;/td&gt;
      &lt;td&gt;
        &lt;ul class="list-unstyled mb-0"&gt;
          &lt;li class="text-nowrap"&gt;000-000-018&lt;/li&gt;
          &lt;li class="text-nowrap"&gt;000-000-019&lt;/li&gt;
          &lt;li class="text-nowrap"&gt;000-000-020&lt;/li&gt;
          &lt;li class="text-nowrap"&gt;+12 more&lt;/li&gt;
        &lt;/ul&gt;
      &lt;/td&gt;
      &lt;td&gt;ABC Corporation&lt;/td&gt;
      &lt;td&gt;
        &lt;ul class="list-unstyled mb-0"&gt;
          &lt;li class="text-nowrap"&gt;Ida Bowden&lt;/li&gt;
          &lt;li class="text-nowrap"&gt;Kristen Connell&lt;/li&gt;
          &lt;li class="text-nowrap"&gt;Eugene Parker&lt;/li&gt;
          &lt;li class="text-nowrap"&gt;&lt;mark&gt;Jane Smith&lt;/mark&gt;&lt;/li&gt;
        &lt;/ul&gt;
      &lt;/td&gt;
    &lt;/tr&gt;
    &lt;tr&gt;
      &lt;td&gt;&lt;a href="#"&gt;L000-002-139-TR02&lt;/a&gt;&lt;/td&gt;
      &lt;td&gt;
        &lt;ul class="list-unstyled mb-0"&gt;
          &lt;li class="text-nowrap"&gt;000-000-018&lt;/li&gt;
          &lt;li class="text-nowrap"&gt;000-000-019&lt;/li&gt;
          &lt;li class="text-nowrap"&gt;000-000-020&lt;/li&gt;
          &lt;li class="text-nowrap"&gt;+12 more&lt;/li&gt;
        &lt;/ul&gt;
      &lt;/td&gt;
      &lt;td&gt;MNO Corporation&lt;/td&gt;
      &lt;td&gt;Eve Smith&lt;/td&gt;
    &lt;/tr&gt;
    &lt;tr&gt;
      &lt;td&gt;&lt;/td&gt;
      &lt;td&gt;&lt;a href="#"&gt;L000-002-140-TR&lt;/a&gt;&lt;/td&gt;
      &lt;td&gt;
        &lt;ul class="list-unstyled mb-0"&gt;
          &lt;li class="text-nowrap"&gt;000-222-333&lt;/li&gt;
        &lt;/ul&gt;
      &lt;/td&gt;
      &lt;td&gt;EFG Corporation&lt;/td&gt;
      &lt;td&gt;&lt;mark&gt;Jane Smith&lt;/mark&gt;&lt;/td&gt;
    &lt;/tr&gt;
  &lt;/tbody&gt;
&lt;/table&gt;
                </code></pre>
        </div>
      </div>
    </div>
  </component-frame>
</template>

<script>
export default {
  name: "tables",
  data() {
    return {
      title: "Tables",
      ascending: false,
      sortColumn: "",
      rows: [
        { name: "12801 Anderson", modified: "Sep 28 2017", status: "Rejected" },
        { name: "Jiles/Roberts", modified: "Sep 17 2107", status: "Defected" },
        { name: "12764 Smith", modified: "Sep 17 2017", status: "Unsubmitted" },
        { name: "12805 Terries", modified: "Sep 04 2017", status: "Pending" },
      ],
    };
  },

  methods: {
    sortTable: function sortTable(col, index) {
      if (this.sortColumn === col) {
        this.ascending = !this.ascending;
      } else {
        this.ascending = true;
        this.sortColumn = col;
      }

      var ascending = this.ascending;

      this.rows.sort(function(a, b) {
        if (a[col] > b[col]) {
          return ascending ? 1 : -1;
        } else if (a[col] < b[col]) {
          return ascending ? -1 : 1;
        }
        return 0;
      });
    },
  },

  computed: {
    columns: function columns() {
      if (this.rows.length == 0) {
        return [];
      }
      return Object.keys(this.rows[0]);
    },
  },
};
</script>
